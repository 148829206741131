import axios from "axios";

import proxy from "../../utils/proxy";

import {
  ITEM_USER_ITEMS,
  ITEM_GET_BY_ID,
  ITEM_INITIAL_STATES,
  ITEM_SELECT,
  ITEM_CLEAR_SELECTED_ITEM,
  ITEM_UPDATE,
  ITEM_RESET_ITEMSOCKET,
  ITEM_NEW,
  ITEM_SET_VALID_FILES,
  ITEM_UPDATE_FAVORITES,
  ITEM_ADD_DATA,
  ITEM_SET_ITEMS_LOADING,
  ITEM_SET_SUBMITTING,
  ITEM_COUNTS,
  ITEM_DELETE,
  NEED_TO_LOAD_ITEMS,
  ITEMS_UPDATE,
  UPDATE_ITEM_TODOS,
  UPDATE_ITEMS_TODOS,
  GET_MY_ITEM_SUMMARY,
  SET_MY_ITEM_SUMMARY,
  UPDATE_MY_ITEMS_TODOS,
  UPDATE_COMBINED_ITEMS_TODO,
  ITEM_COPY,
  SET_ITEM_REDIRECT_URL,
} from "../types/item.types";
import { actionAlert } from "./action.alert";
import { actionUpdateRecentItems } from "./action.auth";
import { actionUpdateTagList } from "./action.commonData";
import { actionClearModals2 } from "./action.modal";
import { actionSocketBasic2 } from "./action.socket";
import handleErrors from "./handleErrors";

let source;

// Valitse yksi tilaus ja tuo sen tiedot Reduxiin
export const actionSelectItem = (item) => (dispatch) => {
  dispatch({
    type: ITEM_SELECT,
    payload: item,
  });
};

// unmount error handle
export const actionCancelItemRequests = () => async (dispatch) => {
  if (source) {
    source.cancel("My items got unmounted");
  }
};

// Muokkaa tilauksen statusta
export const actionUpdateItemStatus = ({ id, status, statusText }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    id,
    status,
    statusText,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-status`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "status_changed",
          data: res.data.data,
        },
      });

      dispatch({
        type: ITEMS_UPDATE,
        payload: res.data.data,
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

// päivitä tilauksen listaa (sub document)
export const actionUpdateItemCheckList = ({
  itemId,
  title,
  notes,
  listId,
  showToCustomer,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ itemId, title, notes, listId, showToCustomer });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-checklist`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: { checkLists: res.data },
        },
      });

      dispatch(actionClearModals2());
      dispatch(actionAlert("Onnistui", "success"));
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return false;
  }
};

export const actionDeleteCheckList = ({ itemId, listId }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/item/delete-checklist/${itemId}/${listId}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: { checkLists: res.data },
        },
      });

      dispatch(actionClearModals2());
      dispatch(actionAlert("Onnistui", "success"));
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

// Lisää tiedosto (kuva) palvelimelle
export const actionUploadFile = (
  myFile,
  fileExtension,
  itemId,
  legend,
  author
) => async (dispatch) => {
  // status 1 on item
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-item-id": itemId,
      "x-file-extension": fileExtension,
      "x-status": 1,
    },
  };

  let formData = new FormData();
  formData.append("myFile", myFile);

  try {
    const res = await axios.post(`${proxy}/api/upload-file`, formData, config);

    if (res.status === 200) {
      const config2 = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { name, format, url, uuid } = res.data;

      const body = {
        id: itemId,
        name: name,
        format: format,
        legend: legend,
        url: url,
        uuid: uuid,
        author: author,
      };

      const res2 = await axios.put(`${proxy}/api/update-images`, body, config2);

      if (res2.status === 200) {
        dispatch({
          type: ITEM_UPDATE,
          payload: {
            event: "new_file",
            data: {},
          },
        });

        return res2.data;
      }
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Resetoi selectedItem
export const actionClearSelectedItem = () => (dispatch) => {
  dispatch({
    type: ITEM_CLEAR_SELECTED_ITEM,
  });
};

export const actionItemInitialStates = () => async (dispatch) => {
  dispatch({
    type: ITEM_INITIAL_STATES,
  });
};

export const actionChangeCustomer = ({
  itemId,
  customer,
  contact,
  manualCustomer,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    customer,
    contact,
    manualCustomer,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/change-customer`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "change_customer",
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

/////////////////////////////////////////////////////////////

// ************************* GET *******************************

// haetaan tilaus
// molemmat käyttää backend palauttaa käyttäjän mukaan
export const actionGetItemById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/item/${id}`, config);

    if (res.status === 200) {
      dispatch({
        type: ITEM_GET_BY_ID,
        payload: res.data.item,
      });

      // päivitetään myItems sivulla näkyvät "Viimeksi avatut" tilauksetu
      if (res.data.recentItems) {
        dispatch(actionUpdateRecentItems(res.data.recentItems));
      }
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

// Hae sisäänkirjautuneen asiakkaan tilaukset
export const actionGetCustomerItems = (filter) => async (dispatch) => {
  source = axios.CancelToken.source();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: source.token,
  };

  const body = JSON.stringify({
    filter,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/customer/item/get-all`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_USER_ITEMS,
        payload: {
          totalCount: res.data.totalCount,
          userItems: res.data.userItems,
        },
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Hae sisäänkirjautuneen yrityksen  tilaukset
export const actionGetCompanyItems = (filter) => async (dispatch) => {
  console.log('GET ***')
  // dispatch({ type: ITEM_RESET_LOADING })
  source = axios.CancelToken.source();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: source.token,
  };

  const body = JSON.stringify({
    filter,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/item/get-all`,
      body,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: ITEM_USER_ITEMS,
        payload: {
          totalCount: res.data.totalCount,
          userItems: res.data.userItems,
        },
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// ************************* POST *******************************
// Tee uusi tilaus
export const actionNewItem = ({
  company,

  title,
  desc,

  location,
  manuf,
  model,
  failDate,
  notice,

  whatDone,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    company,

    title,
    desc,

    location,

    manuf,
    model,
    failDate,
    notice,

    whatDone,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/customer/item/new-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_NEW,
        payload: {
          event: "new_item",
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Tee uusi tilaus yrityksellä
export const actionNewItemCompany = ({
  title,
  desc,
  date,
  deadline,
  duration,
  responsibles,
  location,
  manualCustomer,
  customer,
  contact,
  visibility,
  itemType,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    title,
    desc,
    date,
    deadline,
    duration,
    responsibles,
    location,
    manualCustomer,
    customer,
    contact,
    visibility,
    itemType,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/item/new-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_NEW,
        payload: {
          event: "new_item",
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));
      return res.data.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

// ************************* PUT *******************************

// ************************* DELETE *******************************

// poista tilaus ja liittyvät tiedot
export const actionDeleteItem = ({ itemId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/item/delete/${itemId}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_DELETE,
        payload: {
          id: res.data.id,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Muokkaa tilauksen vastuutettua
export const actionSetResponsibles = ({ itemId, responsibles }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    responsibles,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/set-responsible`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "new_responsible",
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

// Muokkaa tilauksen vastuutettua
export const actionSetItemTags = ({ itemId, tags }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    tags,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/set-tags`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "tags",
          data: {
            tags: res.data.combined,
          },
        },
      });

      dispatch(actionUpdateTagList(res.data.newTags));

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

// Muokkaa tilauksen budjettia
export const actionUpdateBudget = ({ id, costsBudget, hoursBudget }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id, costsBudget, hoursBudget });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-budget`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "budget_update",
          data: res.data,
        },
      });

      return true;
    }
  } catch (err) {
    handleErrors(err);
    return null;
  }
};

// Muokkaa tilauksen tilaa ja aikataulua
export const actionUpdateItem = ({ id, date, duration, deadline }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    id,
    date,
    duration,
    deadline,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "new_schedule",
          data: res.data.data,
        },
      });

      dispatch({
        type: ITEMS_UPDATE,
        payload: res.data.data,
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Muokkaa tilauksen kuvan kuvatekstiä
export const actionUpdateItem2 = ({ id, image }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ id, image });

  try {
    const res = await axios.put(`${proxy}/api/item/update-item2`, body, config);

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Muokkaa tilauksen tuotekortin tekstitietoja
export const actionUpdateItem3 = ({
  id,
  desc,
  location,
  manuf,
  model,
  failDate,
  title,
  whatDone,
  socketId,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    id,
    desc,
    location,
    manuf,
    model,
    failDate,
    title,
    whatDone,
    socketId,
  });

  try {
    const res = await axios.put(`${proxy}/api/item/update-item3`, body, config);

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: res.data.data,
        },
      });

      dispatch({
        type: ITEMS_UPDATE,
        payload: res.data.data,
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionResetItemSocket = () => (dispatch) => {
  dispatch({
    type: ITEM_RESET_ITEMSOCKET,
  });
};

export const actionSetItemData = (data) => (dispatch) => {
  dispatch({
    type: ITEM_UPDATE,
    payload: {
      event: null,
      data: data,
    },
  });
};

// Muokkaa tilauksen tilaa ja aikataulua
export const actionUserIn = (itemId) => async (dispatch) => {
  const event = "user_in";

  dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/user-in`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: event,
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetitemImages = ({ itemId, files }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ itemId, files });

  try {
    const res = await axios.post(`${proxy}/api/item/get-files`, body, config);

    if (res.status === 200) {
      dispatch({
        type: ITEM_SET_VALID_FILES,
        payload: res.data.files,
      });
      return res.data;
    }
  } catch (err) {
    handleErrors(err);
    return null;
  }
};

export const actionItemToggleFavorite = (itemId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/item/toggle-favorite`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE_FAVORITES,
        payload: {
          data: { myFavorite: res.data.value },
          id: res.data.itemId,
        },
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionItemGetContacts = (itemId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/item/get-item-contacts/${itemId}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_ADD_DATA,
        payload: {
          data: res.data,
        },
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionItemSetItemsLoading = (loading, items) => async (
  dispatch
) => {
  dispatch({
    type: ITEM_SET_ITEMS_LOADING,
    payload: {
      loading: loading,
      items: items,
    },
  });
};

// Hae sisäänkirjautuneen yrityksen  tilaukset
export const actionSearchItems = (filter) => async (dispatch) => {
  source = axios.CancelToken.source();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: source.token,
  };

  const body = JSON.stringify({
    filter,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/item/search-items`,
      body,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: ITEM_USER_ITEMS,
        payload: {
          totalCount: res.data.totalCount,
          userItems: res.data.userItems,
          // favoriteItems: res.data.favoriteItems,
        },
      });

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionItemSetSubmitting = (event, status) => async (dispatch) => {
  dispatch({
    type: ITEM_SET_SUBMITTING,
    payload: {
      event: event,
      status: status,
    },
  });
};

export const actionSetItemPerms = ({ itemId, customerCanEdit }) => async (
  dispatch
) => {
  const event = "item_perms";

  dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    customerCanEdit,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/change-item-perms`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: event,
          data: res.data.data,
        },
      });

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetItemCounts = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${proxy}/api/item/get-item-counts`, config);

    if (res.status === 200) {
      dispatch({
        type: ITEM_COUNTS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionAnswerItemField = ({ itemId, field, answer }) => async (
  dispatch
) => {
  const event = "answer";

  dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    field,
    answer,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/answer-item`,
      body,
      config
    );

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: res.data.data,
        },
      });

      dispatch(actionItemSetSubmitting(event, false));

      // dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionSetItemAlerts = ({
  itemId,
  alerts,
  privateAlerts,
}) => async (dispatch) => {
  // dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    alerts,
    privateAlerts,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/alerts-item`,
      body,
      config
    );

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: res.data.data,
        },
      });

      // dispatch(actionItemSetSubmitting(event, false));

      // dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionUpdateItemCompanyFields = ({
  itemId,
  companyTextField,
  notice,
}) => async (dispatch) => {
  // dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    companyTextField,
    notice,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-company-fields`,
      body,
      config
    );

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: res.data.data,
        },
      });

      // dispatch(actionItemSetSubmitting(event, false));

      // dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionLockItem = ({
  itemId,
  group,
  open,
  socketId,
  forceOpen,
  forceAllOpen,
}) => async (dispatch) => {
  // dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    group,
    open,
    socketId,
    forceOpen,
    forceAllOpen,
  });

  try {
    const res = await axios.put(`${proxy}/api/item/lock-item`, body, config);

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          data: res.data.data,
        },
      });

      // dispatch(actionItemSetSubmitting(event, false));

      dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    dispatch(actionClearModals2());

    return null;
  }
};

export const actionBlurItem = (itemId) => async (dispatch) => {
  // dispatch(actionItemSetSubmitting(event, true));

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/blur-item/${itemId}`,
      config
    );

    // res.data sisältää vain muokatut kentät, payload sisällytetään selectedItemiin reducerissa
    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE_FAVORITES,
        payload: {
          data: { myBlur: res.data.value },
          id: res.data.itemId,
        },
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    dispatch(actionClearModals2());

    return null;
  }
};

export const actionUpdateItemCardInfo = ({ itemId, cardInfo }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    cardInfo,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-card-info`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: ITEM_UPDATE,
        payload: {
          event: "",
          data: res.data.data,
        },
      });

      //  dispatch(actionSocketBasic2(res.data.socket));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return null;
  }
};

export const actionItemNeedToLoad = (needToLoad) => (dispatch) => {
  dispatch({
    type: NEED_TO_LOAD_ITEMS,
    payload: needToLoad,
  });
};

export const actionEditTodoItem = ({
  itemId,
  desc,
  todos,
  status,
  title,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    desc,
    todos,
    status,
    title,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/update-todo-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch(actionAlert("Onnistui", "success"));

      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return false;
  }
};

// MITÄ REDUCERIA pitää käyttää
// fromItems -> klikaus tulee "vanhalta" items sivulta
// fromMyItems -> klikattu uudesta todo näkymästä
// compined -> todo näkymässä "yhdistä yhdelle kortille" on päällä
// compined yhdistää todot yhteen "virtuaaliseen" korttiin
export const actionToggleTodoItem = ({
  itemId,
  todoId,
  label,
  done,
  doneBy,
  fromItems,
  fromMyItems,
  compined,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
    todoId,
    label,
    done,
    doneBy,
    combinedItem: compined,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/item/toggle-todo-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch(actionAlert("Onnistui", "success"));

      if (fromItems) {
        dispatch({
          type: UPDATE_ITEMS_TODOS,
          payload: res.data,
        });
      } else if (fromMyItems) {
        if (compined) {
          dispatch({
            type: UPDATE_COMBINED_ITEMS_TODO,
            payload: res.data,
          });
        } else {
          dispatch({
            type: UPDATE_MY_ITEMS_TODOS,
            payload: res.data,
          });
        }
      } else {
        dispatch({
          type: UPDATE_ITEM_TODOS,
          payload: res.data.todos,
        });
      }
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return false;
  }
};

export const actionGetMyItemsSummary = ({
  dateType,
  onlyUnDone,
  combineItems,
  onlyImportant,
  archived,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    dateType,
    onlyUnDone,
    combineItems,
    onlyImportant,
    archived,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/item/get-my-items-summary`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: GET_MY_ITEM_SUMMARY,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return false;
  }
};

export const actionSetMySummary = (myItems, loading) => async (dispatch) => {
  dispatch({
    type: SET_MY_ITEM_SUMMARY,
    payload: {
      myItems: myItems,
      loadingMyItems: loading,
    },
  });
};

export const actionCopyItem = ({ itemId }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    itemId,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/item/copy-item`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch(actionAlert("Onnistui", "success"));

      dispatch({
        type: ITEM_COPY,
        payload: res.data,
      });

      if (res.data && res.data._id) {
        dispatch(actionSetMyItemRedirect(`/item/${res.data._id}`));
      }
    }
  } catch (err) {
    dispatch(handleErrors(err));

    return false;
  }
};

export const actionSetMyItemRedirect = (url) => async (dispatch) => {
  dispatch({
    type: SET_ITEM_REDIRECT_URL,
    payload: url,
  });
};

export const actionSetItemValidFiles = ({ files }) => async (dispatch) => {
  dispatch({
    type: ITEM_SET_VALID_FILES,
    payload: files,
  });
};
