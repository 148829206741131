import React from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import ConfirmPopOver from "../Popovers/ConfirmPopOver";
import HideCheckComponent from "../RestrictComponents/HideCheckComponent";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";

const BasicFileCard = ({
  children,
  file,
  deleteFile,
  permField,
  selectedFile,
  handleSwitchFile,
  handleLegend,
  toggleFileEdit,
  submitFileEdit,
  newLegend,
  newFileDesc,
  fileShowToCustomer,
  requiredPerms,
  supportShowToCustomer,
  isReport
}) => {
  const { userGroup } = useSelector((state) => state.auth.user);
  const { _id: itemId } = useSelector((state) => state.item.selectedItem);

  return (
    <div className="appImgDiv smallShadow">
      {userGroup < 11 ? (
        <HideCheckComponent required={requiredPerms} field={permField}>
          {file.fromItem && file.fromItem === itemId || isReport ? (
            <div className="appButtons">
              <ConfirmPopOver
                title="Vahvista poisto"
                text="Tiedosto poistetaan"
                btnText="Poista"
                action={() => deleteFile(file.name, file.uuid)}
              />

              {selectedFile.uuid !== file.uuid ? (
                <Button
                  variant="link"
                  onClick={() =>
                    toggleFileEdit(
                      file.name,
                      file.uuid,
                      file.legend,
                      file.showToCustomer,
                      file.fileDesc
                    )
                  }
                >
                  Muokkaa
                </Button>
              ) : (
                <>
                  <Button variant="link" onClick={submitFileEdit}>
                    Tallenna
                  </Button>

                  <Button variant="link" onClick={toggleFileEdit}>
                    Peruuta
                  </Button>
                </>
              )}
            </div>
          ) : null}
        </HideCheckComponent>
      ) : file.addedByCustomer ? (
        file.fromItem && file.fromItem === itemId ? (
          <div className="appButtons">
            <ConfirmPopOver
              title="Vahvista poisto"
              text="Tiedosto poistetaan"
              btnText="Poista"
              action={() => deleteFile(file.name, file.uuid)}
            />
            {selectedFile.uuid !== file.uuid ? (
              <Button
                variant="link"
                onClick={() =>
                  toggleFileEdit(
                    file.name,
                    file.uuid,
                    file.legend,
                    file.showToCustomer,
                    file.fileDesc
                  )
                }
              >
                Muokkaa
              </Button>
            ) : (
              <>
                <Button variant="link" onClick={submitFileEdit}>
                  Tallenna
                </Button>

                <Button variant="link" onClick={toggleFileEdit}>
                  Peruuta
                </Button>
              </>
            )}
          </div>
        ) : null
      ) : null}

      {supportShowToCustomer && userGroup < 11 && (
        
        <>
          <Form.Check
            name="fileShowToCustomer"
            checked={
              selectedFile.uuid === file.uuid
                ? fileShowToCustomer
                : file.showToCustomer
            }
            onChange={handleSwitchFile}
            label="Näytä asiakkaalle"
            disabled={file.fromItem !== itemId || file.addedByCustomer || selectedFile.uuid !== file.uuid}
            type="switch"
            id={file.uuid}
          />

          {file.addedByCustomer && selectedFile.uuid === file.uuid && (
            <Form.Text>Asiakas lisännyt</Form.Text>
          )}
        </>
      )}

      <br />
      <Form.Group>
        <Form.Text>Tiedoston nro: {file.index}</Form.Text>
        <Form.Control
          name="newLegend"
          value={selectedFile.uuid === file.uuid ? newLegend : file.legend}
          onChange={handleLegend}
          disabled={selectedFile.uuid !== file.uuid}
        />
      </Form.Group>

      <br />

      {children}

      <br />

      <AutoSizeTextArea
        label=""
        name="newFileDesc"
        onChange={handleLegend}
        isInvalid={undefined}
        onBlur={undefined}
        formikError={undefined}
        value={
          selectedFile.uuid === file.uuid
            ? newFileDesc
            : file.fileDesc
            ? file.fileDesc
            : ""
        }
        disabled={selectedFile.uuid !== file.uuid}
      />

      {!file.fromItem ? <small>EI tuettu kopioinnissa</small> : null}
    </div>
  );
};

export default BasicFileCard;
