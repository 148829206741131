import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import RecordAudio from "./RecordAudio";

import "./SelectFile.scss";
import useUpload from "./useUpload";
import { useSelector } from "react-redux";
export default function SelectFile({
  folder,
  id,
  setFiles,
  supportShowToCustomer,
  supportAudio,
  disableForCustomer,
}) {
  const [states, setStates] = useState({
    showToCustomer: true,
    recordAudio: false,
  });

  const { showToCustomer, recordAudio } = states;

  const { userGroup } = useSelector((state) => state.auth.user);

  // select file and upload logic
  const {
    fileData,
    uploading,
    selectFile,
    uploadFileCompany,
    uploadFileCustomer,
    setFileData,
  } = useUpload(setFiles, folder, id, showToCustomer);

  const onLegendChange = (e) => {
    setFileData({ ...fileData, legend: e.target.value });
  };

  const handleSwitch = (e) => {
    setStates({ ...states, [e.target.name]: e.target.checked });
  };

  if (disableForCustomer && userGroup > 10) {
    return null;
  }

  return (
    <div className="fileForm">
      {supportAudio && (
        <Form.Group>
          <Form.Check
            label="Nauhoita ääntä (testi)"
            onChange={handleSwitch}
            checked={recordAudio}
            name="recordAudio"
          />
        </Form.Group>
      )}
      {recordAudio && supportAudio ? (
        <RecordAudio
          id={id}
          folder={folder}
          uploadFile={
            userGroup < 11
              ? uploadFileCompany
              : userGroup > 10
              ? uploadFileCustomer
              : null
          }
          uploading={uploading}
        />
      ) : (
        <Form
          onSubmit={(e) =>
            userGroup < 11
              ? uploadFileCompany(e)
              : userGroup > 10
              ? uploadFileCustomer(e)
              : null
          }
        >
          <div className="formButtons">
            <Form.File
              id="upload-file1"
              label="Kamera"
              data-browse=""
              name="upload-file"
              accept="image/*"
              capture="camera"
              onChange={(e) => selectFile(e)}
              custom
            />

            <Form.File
              id="upload-file2"
              label="Selaa"
              data-browse="Selaa"
              name="upload-file"
              accept="image/* aplication/pdf"
              onChange={(e) => selectFile(e)}
              custom
            />
          </div>
          <Form.Text>
            Kelpaavat tiedostot: jpg, jpeg, png, pdf, mp4, (mkv - varauksin)
          </Form.Text>
          <Form.Text>Maksimi tiedosto koko: 80Mb</Form.Text>

          <hr />

          <p>
            Tiedosto: {fileData.fileName ? fileData.fileName : "ei valittu"}
            <br />
          </p>
          {fileData.fileExtension === "mkv" && (
            <Form.Text className="text-warning">
              HUOM! Videoille paras formaatti on "mp4. "Mkv"-videoita tukee
              toistaiseksi vain Chrome.
            </Form.Text>
          )}

          <div>
            {uploading ? (
              <Spinner animation="border" />
            ) : fileData.myFile && fileData.myFile !== "" ? (
              <>
                <Form.Group>
                  <Form.Label>Nimi</Form.Label>
                  <Form.Control
                    name="legend"
                    value={fileData.legend}
                    onChange={onLegendChange}
                    disabled={uploading}
                  />
                </Form.Group>

                {userGroup > 10 ? null : supportShowToCustomer &&
                  fileData.myFile ? (
                  <Form.Group>
                    <Form.Check
                      label="Näytä asiakkaalle"
                      onChange={handleSwitch}
                      checked={showToCustomer}
                      name="showToCustomer"
                    />
                  </Form.Group>
                ) : null}

                <Button type="submit" className="primaryBtn">
                  Lisää tiedosto
                </Button>
              </>
            ) : null}
          </div>
        </Form>
      )}
    </div>
  );
}

SelectFile.propTypes = {
  folder: PropTypes.string.isRequired,
  supportShowToCustomer: PropTypes.bool,
  supportAudio: PropTypes.bool,
  disableForCustomer: PropTypes.bool,
};

SelectFile.defaultProps = {
  folder: undefined,
  supportShowToCustomer: false,
  supportAudio: false,
  disableForCustomer: true,
};
