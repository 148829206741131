import axios from "axios";
import {
  CLEAR_COMPANY,
  COMPANY_CUSTOMERS,
  COMPANY_SELECT_CUSTOMER,
  COMPANY_MAIN_MENU,
  COMPANY_NEW_CUSTOMER,
} from "./types";
import proxy from "../../utils/proxy";

import handleErrors from "./handleErrors";
import {
  COMPANY_ADD_FILES,
  COMPANY_BASIC_USERLIST,
  COMPANY_DELETE_FILES,
  COMPANY_GET_CUSTOMER_GROUPS,
  COMPANY_RESET_STATES,
  COMPANY_UPDATE_CUSTOMER,
  COMPANY_UPDATE_CUSTOMERS,
  COMPANY_UPLOADING,
  SET_COMPANY_SOCKET_DATA,
} from "../types/company.types";
import { ITEM_UPDATE } from "../types/item.types";
import { actionSocketBasic2 } from "./action.socket";
import { actionAlert } from "./action.alert";
import { actionClearModals2 } from "./action.modal";
import { actionUpdateTagList } from "./action.commonData";

export const actionGetBasicCompanyUserList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/companyUser/basic-user-list`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_BASIC_USERLIST,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Hae oman yrityksen asiakas yritykset
export const actionGetCompanyCustomers2 = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/customer-company/get-list`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_CUSTOMERS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

// Tyhjennä kaikki company tiedot
export const actionClearCompany = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPANY,
  });
};

export const actionResetCompanyStates = () => (dispatch) => {
  dispatch({
    type: COMPANY_RESET_STATES,
  });
};

/*
export const actionSearchContacts = ({ field, value }) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/contacts/search?field=${field}&value=${value}`,
      config
    );

    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};*/

export const actionGetCompanyMainMenu = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/get-company-main-menu`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_MAIN_MENU,
        payload: res.data,
      });
      return false;
    }
  } catch (err) {
    return true;
  }
};

export const actionCompanyUploadFile = ({
  myFile,
  fileExtension,
  legend,
  folder,
  id,
  showToCustomer,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  let formData = new FormData();
  formData.append("myFile", myFile);
  formData.set("extension", fileExtension);
  formData.set("legend", legend);
  formData.set("showToCustomer", showToCustomer);

  try {
    dispatch(actionCompanySetUploading(true));

    const res = await axios.post(
      `${proxy}/api/company/company/upload-file/${folder}/${id}`,
      formData,
      config
    );

    if (res.status === 200) {
      // dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionCompanySetUploading(false));

      if (folder === "item") {
        dispatch({
          type: ITEM_UPDATE,
          payload: {
            data: {
              history: res.data.data.history,
            },
          },
        });
        dispatch(actionSocketBasic2(res.data.socket));
        return res.data.data;
      } else if (folder === "customer_company") {
        dispatch(actionCompanyUpdateCustomerFiles("add", res.data.newFile));
      } else if (folder === "finalReport") {
        return res.data.data;
      }

      return res.data;
    }
  } catch (err) {
    dispatch(actionCompanySetUploading(false));

    dispatch(handleErrors(err));
    return null;
  }
};

export const actionDeleteImageCompany = ({ folder, id, name, uuid }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.delete(
      `${proxy}/api/company/company/delete-file/${folder}/${id}/${name}/${uuid}`,
      config
    );

    if (res.status === 200) {
      if (res.data.folder === "customer_company") {
        dispatch(actionCompanyUpdateCustomerFiles("delete", res.data.file));
      }

      return true;
    }
  } catch (err) {
    console.error("* DEL err", err);

    dispatch(handleErrors(err));
  }
};

export const actionSetCompanySocketData = ({ users }) => (dispatch) => {
  dispatch({
    type: SET_COMPANY_SOCKET_DATA,
    payload: {
      onlineUsers: users,
    },
  });
};

export const actionGetCustomers = (filter) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    filter,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/customer-company/get-full-list`,
      body,
      config
    );

    if (res.status === 200) {
      if (!res.data.isGroup) {
        // haetaan asiakasyritykset
        dispatch({
          type: COMPANY_CUSTOMERS,
          payload: res.data.list,
        });
      } else {
        // haetaan ns. ryhmät, jotka sama kuin asiakasyritykset, mutta ne halutaan erikseen
        dispatch({
          type: COMPANY_GET_CUSTOMER_GROUPS,
          payload: res.data.list,
        });
      }
    }
  } catch (err) {
    dispatch(handleErrors(err));
  }
};

export const actionGetCustomerById = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${proxy}/api/company/customer-company/get-by-id/${id}`,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_SELECT_CUSTOMER,
        payload: res.data,
      });
      // return res.data;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionSelectCustomer = (customer) => async (dispatch) => {
  dispatch({
    type: COMPANY_SELECT_CUSTOMER,
    payload: customer,
  });
  // return res.data;
};

// Rekisteröi käyttäjän tiedot tietokantaan syötettyjen tietojen mukaisesti (Action)
export const actionAddCustomerComp = ({
  name,
  phone,
  email,
  address,
  city,
  zip,
  desc,
  isGroup,

  address2,
  city2,
  zip2,

  eAddress,
  serviceId,
  operator,

  companyForm,
  businessId,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    name,
    phone,
    email,
    address,
    city,
    zip,
    desc,
    isGroup,

    address2,
    city2,
    zip2,

    eAddress,
    serviceId,
    operator,

    companyForm,
    businessId,
  });

  try {
    const res = await axios.post(
      `${proxy}/api/company/customer-company/add-customer-company`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_NEW_CUSTOMER,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionLinkCustomerData = ({
  customerId,
  tags,
  alerts,
  from,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      customerId,
      tags,
      alerts,
    });

    const res = await axios.post(
      `${proxy}/api/company/customer-company/link-data`,
      body,
      config
    );

    if (res.status === 200) {
      // from tulee frontilta suoraan
      // päättää päivitetäänkö valittu asiakas vai korttinäkymä
      if (from === "customers") {
        dispatch({
          type: COMPANY_UPDATE_CUSTOMERS,
          payload: res.data,
        });
      } else if (from === "oneCustomer") {
        dispatch({
          type: COMPANY_UPDATE_CUSTOMER,
          payload: res.data.customer,
        });
      }

      dispatch(actionUpdateTagList(res.data.newTags));
      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionCompanyUpdateCustomerCompany = ({
  phone,
  email,
  address,
  city,
  zip,
  desc,
  customerCompanyId,
  address2,
  city2,
  zip2,

  eAddress,
  serviceId,
  operator,

  companyForm,
  companyFields,
  businessId,
}) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    phone,
    email,
    address,
    city,
    zip,
    desc,
    customerCompanyId,
    address2,
    city2,
    zip2,

    eAddress,
    serviceId,
    operator,

    companyForm,
    companyFields,
    businessId,
  });

  try {
    const res = await axios.put(
      `${proxy}/api/company/customer-company/update-customer-company`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_UPDATE_CUSTOMER,
        payload: res.data,
      });

      dispatch(actionClearModals2());
      dispatch(actionAlert("Onnistui", "success"));
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionToggleCustomerFavorite = (customerId) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      customerId,
    });

    const res = await axios.post(
      `${proxy}/api/company/customer-company/toggle-favorite`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_UPDATE_CUSTOMERS,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionGetCustomerFiles = ({ customerId, files }) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ customerId, files });

  try {
    const res = await axios.post(
      `${proxy}/api/company/customer-company/logo-image`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_UPDATE_CUSTOMER,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    handleErrors(err);
    return null;
  }
};

export const actionCompanySetUploading = (uploading) => (dispatch) => {
  dispatch({
    type: COMPANY_UPLOADING,
    payload: uploading,
  });
};

export const actionToggleCustomerArchive = (customerId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      customerId,
    });

    const res = await axios.post(
      `${proxy}/api/company/customer-company/toggle-archived`,
      body,
      config
    );

    if (res.status === 200) {
      dispatch({
        type: COMPANY_UPDATE_CUSTOMERS,
        payload: res.data,
      });

      dispatch(actionAlert("Onnistui", "success"));
      dispatch(actionClearModals2());
      return true;
    }
  } catch (err) {
    dispatch(handleErrors(err));
    return null;
  }
};

export const actionCompanyUpdateCustomerFiles = (type, file) => (dispatch) => {
  if (type === "add") {
    dispatch({
      type: COMPANY_ADD_FILES,
      payload: file,
    });
  } else if (type === "delete") {
    dispatch({
      type: COMPANY_DELETE_FILES,
      payload: file,
    });
  }
};
